<template>
  <v-snackbar
    :app="opts.app"
    :top="opts.top"
    :bottom="opts.bottom"
    :left="opts.left"
    :right="opts.right"
    v-model="opts.show"
    :timeout="opts.timeout"
    :color="opts.color"
    :transition="opts.transition"
    :content-class="opts.contentClass"
  >
    <v-icon v-if="opts.icon" color="white" left>{{ opts.icon }}</v-icon>
    {{ opts.text }}
    <template v-slot:action="{ attrs }">
      <v-btn v-if="opts.showClose" text v-bind="attrs" @click="opts.show = false">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'
import { onSnackbar } from '../hooks'
import clone from 'lodash/clone'

export default defineComponent({
  setup() {
    const defaultOpts = {
      text: '',
      show: false,
      showClose: false,
    }

    const opts = reactive(clone(defaultOpts))
    onSnackbar(payload => {
      let data = Object.assign(defaultOpts, payload)
      for (const e in data) {
        opts[e] = data[e]
      }
      opts.color = opts.color || 'black'
      opts.show = true
    })

    return {
      opts,
    }
  },
})
</script>
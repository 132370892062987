<template>
  <v-row justify="center">
    <v-col cols="12" lg="5" md="6" sm="8" xs="8">
      <novo-logo :smallOnly="$route.meta.logoSmallOnly"></novo-logo>
      <v-form ref="form" v-model="validSignIn" lazy-validation @submit.prevent="login">
        <v-card flat color="transparent">
          <v-card-text>
            <v-text-field v-model="email" outlined label="Email" :rules="emailRules" />
            <v-text-field
              v-model="password"
              outlined
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              hide-details
              @click:append="showPassword = !showPassword"
            />
            <v-row no-gutters justify="end" class="ma-0">
              <v-checkbox v-model="rememberMe" label="Remember me" hide-details class="mt-2"></v-checkbox>
            </v-row>
          </v-card-text>

          <v-card-actions class="mx-2">
            <v-btn
              type="submit"
              color="button"
              class="white--text"
              block
              x-large
              depressed
              @click="login"
              :loading="busy"
              :disabled="!validSignIn || !email || !password"
            >Sign in</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-row class="mt-4" justify="center">
        <v-btn
          text
          color="secondary"
          :to="{ name: 'PasswordReset', query: $route.query }"
        >Forgot Password</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import router from '../router'
import { validateEmail } from '../helpers/validators'
import { config, getAccessToken, getRefreshToken, registerIdentity } from '../shared-ui/store/sso'
import { ref } from '@vue/composition-api'
import { getUserIdToken, signIn } from '../shared-ui/store/googleapis'
import { broadcastSnackbar } from '../shared-ui/hooks'
import { useCookie, useLocalStorage } from '../shared-ui/helpers/use'
import NovoLogo from '../components/NovoLogo.vue'
import { setAuthStatus } from '@arrowhealth/bridge-sdk'

export default defineComponent({
  components: { NovoLogo },
  setup() {
    const form = ref(null)
    const validSignIn = ref(false)
    const busy = ref(false)
    const rememberNumDays = 10 * 60 * 60 * 1000
    const email = useCookie('username', rememberNumDays, process.env.VUE_APP_SIGN_USER)
    const password = ref(process.env.VUE_APP_SIGN_PASS)
    const emailRules = [v => !!v || 'Email is required', v => validateEmail(v) || 'A valid email is required.']
    const showPassword = ref(false)
    const rememberMe = useCookie('rememberMe', rememberNumDays, false)
    const bridgeRedirectUrl = useLocalStorage('auth-bridge::redirect', { expiresInMinutes: 5 })

    const resetLoginForm = () => {
      if (!rememberMe.value) {
        email.value = ''
      }
      password.value = ''
      form.value.resetValidation()
    }

    const redirectAfterLogin = async () => {
      const firebaseIdToken = await getUserIdToken()
      // console.log('firebaseIdToken:', firebaseIdToken)
      const refreshToken = await getRefreshToken(firebaseIdToken)
      // console.log('SSO Code (aka refreshToken):', refreshToken)
      const urlParams = new URLSearchParams(window.location.search)

      // Try an register Auth0 Identity
      try {
        await registerIdentity()
      } catch (e) {
        // register identity
      }

      setAuthStatus('pass')

      // TODO: Cleanup - duplicated in router/index.js
      let redirectUrl = bridgeRedirectUrl.value || urlParams.get('redirect')
      bridgeRedirectUrl.value = ''
      if (redirectUrl) {
        const url = new URL(decodeURIComponent(redirectUrl))
        url.searchParams.append(config.REFRESH_TOKEN_ID, refreshToken)
        if (urlParams.has('debug')) {
          await getAccessToken(refreshToken)
          // const accessToken = await getAccessToken(refreshToken)
          // console.log('ACCESS TOKEN:', accessToken)
        } else {
          resetLoginForm()
          location.href = url.toString()
        }
      } else {
        await getAccessToken(refreshToken)
        // const accessToken = await getAccessToken(refreshToken)
        // console.log('ACCESS TOKEN:', accessToken)
        router.push({ name: 'Profile', query: router.currentRoute.query })

        setTimeout(() => resetLoginForm, 500)
      }
    }

    const login = async () => {
      if (busy.value) {
        return
      }

      busy.value = true

      try {
        await signIn(email.value, password.value)
        busy.value = false
        redirectAfterLogin()
      } catch (e) {
        busy.value = false
        switch (e.code) {
          case 'auth/user-not-found':
            broadcastSnackbar({
              text: `The username ${email.value} was not found`,
              showClose: true,
            })
            break
          case 'auth/wrong-password':
            broadcastSnackbar({
              text: `The password is incorrect.`,
              showClose: true,
            })
            break
          default:
            broadcastSnackbar({
              text: 'An unknown error occurred',
            })
        }
      }
    }

    return {
      busy,
      email,
      emailRules,
      form,
      password,
      login,
      rememberMe,
      showPassword,
      validSignIn,
    }
  },
})
</script>

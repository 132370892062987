<template>
  <v-row v-if="showContent" justify="center">
    <v-col cols="12" lg="5" md="6" sm="8" xs="8">
      <v-card color="transparent" flat>
        <v-card-title class="title">
          <div class="text-md-center">{{ title }}</div>
        </v-card-title>

        <v-card-text>
          <div class="py-2">{{ content }}</div>
        </v-card-text>

        <v-card-actions class="text-md-center">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            width="100%"
            max-width="320"
            x-large
            :to="{ name: 'Login', replace: true, query: $route.query }"
            >Go to Login</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { signOut } from '../shared-ui/store/googleapis'
import { setAuthStatus } from '@arrowhealth/bridge-sdk'
import router from '../router'

export default defineComponent({
  setup() {
    const urlParams = new URLSearchParams(window.location.search)

    const showContent = computed(() => {
      return urlParams.has('auto') || urlParams.has('error')
    })

    const title = computed(() => {
      if (urlParams.get('auto')) {
        return `Your Session Has Expired`
      }
      if (urlParams.get('error')) {
        return `Your Session Has Expired`
      }
      return ''
    })

    const content = computed(() => {
      if (urlParams.get('auto')) {
        return `In order to keep Protected Health Information safe, we periodically check to see
            if you're still here. Your session has expired due to inactivity. Log back in to
            continue.`
      }
      if (urlParams.get('error')) {
        return `The application you are using requires you to log in again. Please click to continue.`
      }
      return ''
    })

    signOut()
    setAuthStatus('require')

    if (!showContent.value) {
      let query = router.currentRoute.query
      delete query.auto
      delete query.error
      router.push({ name: 'Login', replace: true, query })
    }

    return {
      content,
      title,
      showContent,
    }
  },
})
</script>

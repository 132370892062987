
<template>
  <svg :height="size" viewBox="0 0 24 24">
    <path
      :fill="color"
      d="M13.9,3.8h2.8V1h-2.8V3.8z M20.5,9.4h1.8V7.6h-1.8V9.4z M16.7,7.6h3.8V3.8h-3.8V7.6z M1.5,3.9v7.3V23h9.8
	h9.3V11.2h-3.9v4.1h-3.6V19h-4v-3.6H5.3v-4.1h3.8V7.6h4v3.6h3.6V7.6h-3.6V3.9H9.2H1.5z M20.5,3.8h2.1V1.7h-2.1V3.8z"
    />
    />
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
  setup() {},
})
</script>

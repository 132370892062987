<template>
  <v-avatar
    :color="color"
    :width="size"
    :height="size"
    :min-width="size"
    :style="customStyle"
    @click="$emit('click')"
  >
    <v-img v-if="image" :width="size" :height="size" :max-width="size" :src="image" />
    <div v-else-if="name" class="initials">{{ initials(name) }}</div>
    <div v-else>
      <v-icon size="64">mdi-shield-account</v-icon>
    </div>
  </v-avatar>
</template>

<style scoped>
.initials {
  position: relative;
  text-decoration: none !important;
}
</style>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: 'primary',
    },
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 32,
    },
    // font: {
    //   type: String,
    //   default: 'body-1 white--text',
    // },
    // fontStyle: {
    //   type: String,
    //   default: '',
    // },
  },
  setup() {

    const initials = (val = '') => {
      let names = val.split(' ')
      if (names.length > 0) {
        return names[0].substring(0, 1).toUpperCase() + names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return names[0].substring(0, 1).toUpperCase()
    }

    // watch(() => props.name, (val) => console.log('name:', val), { immediate: true })

    return {
      initials,
    }
  },
})
</script>

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    dark: false,
    customProperties: true,
    themes: {
      light: {
        primary: colors.blue,
        secondary: colors.blue,
        alert: colors.orange,
        button: colors.green,
        label: colors.grey,
        background: colors.shades.white,
        logo: colors.blue.darken1,
      },
      dark: {
        primary: colors.blue.lighten1,
        secondary: colors.grey.lighten3,
        alert: colors.orange,
        button: colors.green,
        label: colors.white,
        background: '#313648',
        logo: colors.white,
      },
    },
  },
})

vuetify.getTheme = () => {
  const mode = vuetify.framework.theme.dark ? 'dark' : 'light'
  return vuetify.framework.theme.defaults[mode]
}

export default vuetify

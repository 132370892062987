import bus from '../helpers/bus'
const SNACKBAR_HOOK = 'hook:snackbar'

export const broadcastSnackbar = data => {
  bus.$emit(SNACKBAR_HOOK, data)
}

export const onSnackbar = handler => {
  bus.$on(SNACKBAR_HOOK, handler)
}

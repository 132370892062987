import { HttpClient } from '../../helpers/httpClient'

const beforeUrlHandler = ({ baseUrl, uri, queryParams }) => {
  queryParams.key = 'AIzaSyD8RAiis0W0qO55eHZvsXR1rrylJuqa58Y'
  return { baseUrl, uri, queryParams }
}

// curl 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD8RAiis0W0qO55eHZvsXR1rrylJuqa58Y' \
// -H 'Content-Type: application/json' \
// --data-binary '{"email":"rob@novorpm.com","password":"test1234","returnSecureToken":true}'
export const identityToolkitClient = HttpClient.create({
  baseUrl: 'https://identitytoolkit.googleapis.com/v1',
  beforeUrlHandler,
})

// curl 'https://securetoken.googleapis.com/v1/token?key=AIzaSyD8RAiis0W0qO55eHZvsXR1rrylJuqa58Y' \
// -H 'Content-Type: application/json' \
// --data-binary '{"grant_type":"refresh_token","refresh_token":"AGEhc0CvAtoQJtwxsySr79GdYNVH0rh8chVlAGcaxGV2S-BKLwvDpyQIO5JFghwb4z1iGAkr6LhW00J8uJiMew7zRjvMh9ExwBPDJor-HJMffA1td9ZgMYZx0lY9pWTek8_kOyzZ6SpdmR2xRLdwZ9S-pcGcN9wIUcJV9ILZuo9_uMCeV-OEpy3ZRNi5BOvLjIubRj70W5RSxWtFqf3hwUpeoGoPq6XHM8FsNFiFa3qbpNFgIr-YxJo"}'
export const secureTokenClient = HttpClient.create({
  baseUrl: 'https://securetoken.googleapis.com/v1',
  beforeUrlHandler,
})

import VueCompositionAPI, { computed, reactive, readonly } from '@vue/composition-api'
import { getCookie, setCookie } from '../helpers/cookie'

import { HttpClient } from '../helpers/httpClient'
import Vue from 'vue'
import { broadcastLogoutRequest } from '../hooks/auth'
import { isStandalone } from './url'
import { onAuthChanged } from '../hooks/auth'
import { setAuthStatus } from '@arrowhealth/bridge-sdk'
import { useLocalStorage } from '../helpers/use'

if (!process.env.VUE_APP_SSO_API_URL) {
  throw new Error('VUE_APP_SSO_API_URL not defined in .env')
}

export const beforeRequestHandler = async (ctx, request) => {
  const accessToken = await getAccessToken()
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  } else {
    broadcastLogoutRequest({ error: 'missing refresh token' })
  }
  return request
}

const skipAuth = ['GetAccessToken', 'GetRefreshToken']

export const ssoClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_SSO_API_URL,
  beforeRequestHandler(ctx, request) {
    if (skipAuth.some(substring => { return ctx.url.includes(substring) })) {
      return request
    }
    return beforeRequestHandler(ctx, request)
  },
})

Vue.use(VueCompositionAPI)

onAuthChanged(authUser => {
  // if there is no auth user then set the user to null
  if (!authUser) {
    setUser(null)
  }
})

const DEV_MODE = 'development'
const REDIRECT_QUERY_KEY = 'redirect'

export const config = {
  SSO_APP_URL: process.env.VUE_APP_SSO_APP_URL,
  CODE: 'code', // this code comes from Auth0
  REFRESH_TOKEN_ID: 'rtok',
  ACCESS_TOKEN_ID: 'atok',
  AUTH0_TOKEN_ID: 'a0tok',
}

const _state = reactive({
  user: null,
  users: [],
})
export const state = readonly(_state)

export const getOrgId = async () => {
  if (!_state.user) {
    let userId = await getUserId()
    await getUser({ userId })
  }
  if (_state.user) {
    return _state.user.orgId
  }
  return ''
}

export const getUsers = async () => {
  const accessToken = await getAccessToken()
  const { data } = await ssoClient.post(
    '/GetUsers',
    {},
    {},
    {
      Authorization: `Bearer ${accessToken}`,
    }
  )
  _state.users = data.users
  return data.users
}

export const displayName = computed(() => {
  if (_state.user) {
    return _state.user.firstName + ' ' + _state.user.lastName
  }
  return ''
})

export const photoUrl = computed(() => {
  if (_state.user) {
    return _state.user.photoUrl
  }
  return ''
})

export const email = computed(() => {
  if (_state.user) {
    return _state.user.email
  }
  return ''
})

export const initials = computed(() => {
  if (_state.user) {
    return _state.user.firstName.substring(0, 1) + ' ' + _state.user.lastName.substring(0, 1)
  }
  return ''
})

/**
 * Property used to determine if person is Novo admin
 */
export const isNovoAdmin = computed(() => {
  return _state.user?.email?.includes(['novorpm.com', 'arrowhealth.io'])
})

export const setUser = user => {
  _state.user = user
}

export const setSSOAppUrl = url => {
  config.baseUrl = url
}

export const parseJWT = token => {
  if (token) {
    const base64UrlSlice = token.split('.')
    if (base64UrlSlice.length) {
      const base64Url = base64UrlSlice[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      return JSON.parse(jsonPayload)
    }
  }
}

export const setRefreshToken = refreshToken => {
  let expires = new Date()
  expires.setFullYear(expires.getFullYear() + 100)
  setCookie(config.REFRESH_TOKEN_ID, refreshToken, expires)
}

export const getRefreshTokenFromQueryParams = () => {
  // 1. from url query param code
  // Check if the refresh token is being passed
  // if so, grab it and then remove it from the url
  const url = new URL(window.location.href)
  const token = url.searchParams.get(config.REFRESH_TOKEN_ID)
  url.searchParams.delete(config.REFRESH_TOKEN_ID)
  // replace state without reload
  // https://www.geeksforgeeks.org/how-to-modify-url-without-reloading-the-page-using-javascript/
  window.history.replaceState({}, '', url.toString())
  if (token) {
    setRefreshToken(token)
  }
  return token
}

/**
 * Retrieving the refresh token will happen in this order
 * 1. from firebaseIdToken
 * 2. from cookie
 *
 * @param {string} firebaseIdToken (default: null)
 * @param {boolean} storeInCookie (defatul: true)
 * @returns string | undefined
 */
export const getRefreshToken = async (firebaseIdToken = null) => {
  // 1. check if refresh token exists in query param
  // if there was an refresh token passed in
  // set the refresh token locally, this will allow the app to
  // retrieve an access token later
  // update the last activity (shared between all applications)
  // because this is a new login that occurred
  let token = getRefreshTokenFromQueryParams()
  if (token) {
    return token
  }

  // 1. from cookie
  token = getCookie(config.REFRESH_TOKEN_ID)
  if (token) {
    return token
  }

  // 3. from firebaseIdToken
  if (firebaseIdToken) {
    const { data } = await ssoClient.post('/GetRefreshToken', {
      firebaseIdToken,
    })
    if (data.token) {
      setRefreshToken(data.token)
    }
    return data.token
  }
}

const setAccessToken = accessToken => {
  let jwt = parseJWT(accessToken)
  setCookie(config.ACCESS_TOKEN_ID, accessToken, new Date(jwt.exp * 1000))
}

export const getAccessToken = async (refreshToken = null) => {
  let token = getCookie(config.ACCESS_TOKEN_ID)
  if (token) {
    const { exp } = parseJWT(token)
    if (new Date() < new Date(exp * 1000)) return token
  }
  if (!refreshToken) {
    refreshToken = await getRefreshToken()
  }
  if (refreshToken) {
    const { data } = await ssoClient.post('/GetAccessToken', {
      refreshToken,
    })
    setAccessToken(data.token)
    return data.token
  }
}

export const clearTokens = () => {
  setCookie(config.REFRESH_TOKEN_ID, '', Date.now())
  setCookie(config.ACCESS_TOKEN_ID, '', Date.now())
}

export const getUserId = async () => {
  const accessToken = await getAccessToken()
  if (accessToken) {
    const { sub } = parseJWT(accessToken)
    return sub
  }
}

/**
 * Get User
 * @param {{userId?: string, userRefId?: string}} options
 */
export const getUser = async ({ userId, userRefId }) => {
  const { data } = await ssoClient.post('/GetUserProfile', {
    userId,
    userRefId,
  })
  setUser(data.user)
  return data.user
}

export const registerUser = async userIdToken => {
  const { data } = await ssoClient.post('/RegisterUser', {
    userIdToken,
  })
  return data
}
const auth0IdToken = useLocalStorage(config.AUTH0_TOKEN_ID, { expiresInMinutes: 5 })
export const getAuth0IdToken = async (code, redirectUri) => {
  const { data } = await ssoClient.post('/GetAuth0IdToken', {
    code,
    redirectUri,
  })
  // console.log('getAuth0IdToken--result:', data.token)
  auth0IdToken.value = data.token
  return data.token
}

export const getRefreshTokenFromAuth0IdToken = async () => {
  if (auth0IdToken.value) {
    const { data } = await ssoClient.post('/GetRefreshToken', {
      auth0IdToken: auth0IdToken.value,
    })
    setRefreshToken(data.token)
    return data.token
  }
  // console.log('getRefreshTokenFromAuth0IdToken - MISSING REQUIRED Auth0IdToken')
}

export const registerIdentity = async () => {
  const jwt = auth0IdToken.value
  auth0IdToken.value = ''
  if (jwt) {
    const identity = parseJWT(jwt)
    const accessToken = await getAccessToken()
    await ssoClient.post(
      '/RegisterIdentity',
      {
        connection: 'bridge-oauth',
        isSocial: false,
        provider: 'auth0',
        userId: identity.sub,
      },
      {},
      {
        Authorization: `Bearer ${accessToken}`,
      }
    )
  } else {
    console.log('NO AUTH0 TOKEN FOR registerIdentity')
  }
}

export const updateUser = async ({ firstName, lastName, settings }) => {
  const payload = {}
  if (firstName !== undefined) {
    payload.firstName = { value: firstName }
  }
  if (lastName !== undefined) {
    payload.lastName = { value: lastName }
  }
  if (settings !== undefined) {
    payload.settings = { value: settings }
  }
  
  await ssoClient.post('/UpdateUserProfile', payload)
  // updated user not returned, caller must fetch again if they want the updated user
}

// :: Navigation :: //
export const navigateToSSO = (queryParams = {}) => {
  const url = new URL(config.SSO_APP_URL)
  const redirectUrl = encodeURIComponent(location.href)
  url.searchParams.append(REDIRECT_QUERY_KEY, redirectUrl)
  for (const key in queryParams) {
    if (Object.hasOwnProperty.call(queryParams, key)) {
      const value = queryParams[key]
      url.searchParams.append(key, value)
    }
  }
  const logoutSSOUrl = url.origin + '/logout?' + url.searchParams.toString()
  if (process.env.NODE_ENV === DEV_MODE) {
    // console.log('%c[AUTO-LOGOUT]', 'color: cyan;', logoutSSOUrl)
  } else if (isStandalone) {
    location.href = logoutSSOUrl
  } else {
    // console.log('sending no auth status to bridge...')
    setAuthStatus('require')
  }
}

<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="5" md="6" sm="8" xs="8">
        <user-avatar
          v-if="ssoState.user"
          :size="100"
          :color="color"
          :name="displayName"
          :image="photoUrl"
          class="avatar mt-6 mt-sm-12"
        ></user-avatar>
        <v-card color="transparent" flat class="d-flex flex-column pt-6">
          <v-card-text class="px-2 pb-0 d-flex flex-column flex-grow-1">
            <div class="d-flex flex-row align-center justify-center card-body flex-grow-1">
              <div class="text-center body-2 mt-n6">
                <div class="my-4">You are logged in as</div>
                <!-- Display Name -->
                <div v-if="displayName" class="card-title" :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">
                  {{ displayName }}
                </div>
                <v-skeleton-loader v-else height="34" width="220" type="card"></v-skeleton-loader>
                <!-- Email -->
                <div v-if="email" class="subtitle-1 pt-1">{{ email }}</div>
                <v-skeleton-loader v-else height="18" width="220" type="card"></v-skeleton-loader>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="px-2 pt-12 justify-center">
            <v-btn
              dark
              outlined
              x-large
              color="red"
              width="100%"
              max-width="320"
              class="text-capitalize mt-3"
              @click="logout"
            >
              <span v-if="$vuetify.theme.dark" class="white--text font-weight-medium">Logout</span>
              <span v-else class="font-weight-medium">Logout</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.profile-card {
  position: relative;
}

.avatar {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: 3px solid darkslateblue !important;
  font-size: 32px;
  font-weight: 300;
  border-radius: 50%;
  color: white;
  z-index: 1;
}

.theme--dark .avatar {
  border: 3px solid white !important;
}

.card-title {
  font-size: 28px;
  font-weight: 300;
}
</style>

<script>
import { defineComponent } from '@vue/composition-api'
import UserAvatar from '../shared-ui/components/UserAvatar.vue'
import { broadcastAuthChanged } from '../shared-ui/hooks'
import NovoIcon from '../shared-ui/icons/NovoIcon.vue'
import { clearTokens, displayName, email, photoUrl, state as ssoState } from '../shared-ui/store/sso'
import router from '../router'

export default defineComponent({
  components: { UserAvatar, NovoIcon },
  props: {
    color: {
      type: String,
      default: '#00A2E8',
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const logout = () => {
      clearTokens()
      broadcastAuthChanged(null)
      router.push({ name: 'Login', query: router.currentRoute.query })
    }

    // onAppReady(
    //   authUser => {
    //     if (!authUser) {
    //       router.push({ name: 'Login', replace: true, query: router.currentRoute.query })
    //     }
    //   },
    //   { skipLastActivityCheck: true, skipNavToSSO: true }
    // )

    return {
      displayName,
      email,
      logout,
      photoUrl,
      ssoState,
    }
  },
})
</script>

<template>
  <v-row justify="center">
    <v-col cols="12" lg="5" md="6" sm="8" xs="8">
      <v-card flat color="transparent">
        <novo-logo :smallOnly="$route.meta.logoSmallOnly"></novo-logo>
        <v-card-text>
          <h2>Password Reset</h2>
          <p class="py-6">
            Enter the email address that you used to register. We'll send you an email with a link to reset your
            password.
          </p>
          <v-form ref="formSignIn" v-model="valid" lazy-validation>
            <v-text-field v-model="email" outlined label="Email" :rules="emailRules" />
          </v-form>
          <v-alert v-if="sent" border="top" color="alert" type="info">
            Please check your email and follow the instructions to reset your password.
          </v-alert>
        </v-card-text>
        <v-card-actions v-if="!sent">
          <v-row no-gutters class="px-2">
            <v-btn
              color="primary"
              block
              x-large
              depressed
              class="white--text"
              @click="sendPasswordLink"
              :loading="busy"
              :disabled="!valid || !email"
            >
              <span>Send Link</span>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-row class="my-4" justify="center">
        <v-btn text color="secondary" exact :to="{ name: 'Login', query: $route.query }">Back To Login</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { validateEmail } from '@/helpers/validators'
import { sendPasswordResetEmail } from '../shared-ui/store/googleapis'
import { broadcastSnackbar } from '../shared-ui/hooks'
import NovoLogo from '../components/NovoLogo.vue'

export default defineComponent({
  components: { NovoLogo },
  setup() {
    const emailRules = [v => !!v || 'Email is required', v => validateEmail(v) || 'A valid email is required.']
    const email = ref(process.env.VUE_APP_SIGN_USER)
    const valid = ref(false)
    const busy = ref(false)
    const sent = ref(false)

    const sendPasswordLink = async () => {
      busy.value = true
      try {
        await sendPasswordResetEmail(email.value)
        sent.value = true
      } catch (e) {
        broadcastSnackbar({
          text: `Invalid or unrecognized email address. If you continue to have this issue, please contact support.`,
          showClose: false,
          timeout: 5000,
        })
      }
      busy.value = false
    }

    return {
      busy,
      email,
      emailRules,
      sendPasswordLink,
      sent,
      valid,
    }
  },
})
</script>
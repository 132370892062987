import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCompositionAPI from '@vue/composition-api'
import { setConsoleLabel } from './shared-ui/helpers/console'
import PortalVue from 'portal-vue'

Vue.use(PortalVue)
Vue.use(VueCompositionAPI)
Vue.config.productionTip = false

setConsoleLabel('SSO')

new Vue({
  router,
  vuetify,
  render: h => h(App),
  mounted() {
    document.getElementsByClassName('busy-spinner')[0].remove()
  },
}).$mount('#app')

function validateName(name) {
  name = (name + '').trim()
  return name.length >= 2
}


function validateEmail(email) {
  if (email) {
    return /^\w+([.-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email)
  }
  return false
}

export { validateEmail, validateName }

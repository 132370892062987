<template>
  <v-app>
    <v-main class="no-scroll background">
      <v-container fluid>
        <div v-if="isStandalone">
          <portal-target name="header"></portal-target>
          <v-slide-x-reverse-transition>
            <app-switcher v-show="showAppSwitcher" class="switcher"></app-switcher>
          </v-slide-x-reverse-transition>
        </div>
        <transition :name="$route.meta.transition" mode="out-in">
          <router-view />
        </transition>
      </v-container>
    </v-main>

    <!-- Snackbar Message -->
    <snackbar-message></snackbar-message>

    <!-- Snackbar Refresh -->
    <snackbar-refresh></snackbar-refresh>
  </v-app>
</template>

<style scoped>
.switcher {
  position: fixed;
  top: 10px;
  right: 10px;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>

<style>
.clickable {
  cursor: pointer;
}

.v-btn {
  text-transform: capitalize !important;
}

.no-scroll {
  overflow: hidden;
}
</style>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import vuetify from './plugins/vuetify'
import { onAuthChanged } from './shared-ui/hooks'
// import { updateAuth } from '@novohealth/novo-bridge-js-sdk'
import AppSwitcher from './shared-ui/components/AppSwitcher.vue'
import NovoLogo from './components/NovoLogo.vue'
import SnackbarRefresh from './shared-ui/components/SnackbarRefresh.vue'
import SnackbarMessage from './shared-ui/components/SnackbarMessage.vue'
import { isStandalone } from './shared-ui/store/url'

export default defineComponent({
  components: { NovoLogo, AppSwitcher, SnackbarRefresh, SnackbarMessage },
  setup() {
    const user = ref(null)

    const urlParams = new URLSearchParams(window.location.search)

    const showAppSwitcher = computed(() => {
      return !!user.value
    })

    onAuthChanged(async authUser => {
      // TODO: Later check if there is more than one org (when supported)
      user.value = authUser
    })

    if (['true', '1'].includes(urlParams.get('dark'))) {
      vuetify.framework.theme.dark = true
    }

    document.body.style.backgroundColor = vuetify.getTheme().background

    return {
      isStandalone,
      showAppSwitcher,
      user,
    }
  },
})
</script>

import Vue from 'vue'
import VueCompositionAPI, { reactive, shallowReadonly } from '@vue/composition-api'
import { onAuthChanged } from '../hooks'
import { identityToolkitClient } from './clients/googleapi'
import { clearTokens } from './sso'
import { getCookie, setCookie } from '../helpers/cookie'

Vue.use(VueCompositionAPI)

const FIREBASE_AUTHUSER = 'fb:authUser'

const firebaseAuthUser = JSON.parse(getCookie(FIREBASE_AUTHUSER) || 'null')

onAuthChanged(async authUser => {
  if (!authUser) {
    signOut()
  }
})

const _state = reactive({
  firebaseAuthUser,
})
export const state = shallowReadonly(_state)

export const getUserIdToken = async () => {
  if (_state.firebaseAuthUser) {
    return _state.firebaseAuthUser.idToken
  }
  return ''
}

export const signIn = async (email, password) => {
  const { data } = await identityToolkitClient.post('/accounts:signInWithPassword', {
    email,
    password,
    returnSecureToken: true,
  })
  setCookie(FIREBASE_AUTHUSER, JSON.stringify(data))
  _state.firebaseAuthUser = data
  console.log(data)
  return data
}

export const signOut = async () => {
  clearTokens()
  setCookie(FIREBASE_AUTHUSER, '', Date.now())
  _state.firebaseAuthUser = null
}

export const sendPasswordResetEmail = async email => {
  const { data } = await identityToolkitClient.post('/accounts:sendOobCode', {
    requestType: 'PASSWORD_RESET',
    email,
  })
  console.log(data)
  return data
}

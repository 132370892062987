<template>
  <v-row no-gutters justify="center">
    <novo-logo :size="logoSize" :color="logoColor" class="novo-logo-icon" :class="novoIconClass"></novo-logo>
    <div class="novo-logo-spacer" :class="spacerClass"></div>
  </v-row>
</template>

<style scoped>
.novo-logo-icon {
  transition: margin-top, margin-bottom, left, height;
  transition-duration: 500ms;
  margin-top: 64px;
  margin-bottom: 24px;
}

.novo-logo-icon.small {
  margin-top: 0;
}

.novo-logo-spacer {
  transition: flex 500ms;
}

.novo-logo-spacer.expanded {
  flex: 1;
}
</style>

<script>
import { computed, defineComponent, ref, toRefs, watchEffect } from '@vue/composition-api'
import vuetify from '../plugins/vuetify'
import NovoLogo from '../shared-ui/icons/NovoLogo.vue'

export default defineComponent({
  components: { NovoLogo },
  props: {
    smallOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const logoColor = computed(() => {
      return vuetify.getTheme().logo
    })
    const logoSize = ref(64)
    const spacerClass = ref('')
    const novoIconClass = ref('')
    const { smallOnly } = toRefs(props)

    watchEffect(() => {
      if (smallOnly.value) {
        logoSize.value = 32
        spacerClass.value = 'expanded'
        novoIconClass.value = 'small'
      } else {
        logoSize.value = 64
        spacerClass.value = ''
        novoIconClass.value = ''
      }
    })

    return {
      logoColor,
      logoSize,
      novoIconClass,
      spacerClass,
    }
  },
})
</script>

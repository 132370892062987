<template>
  <div v-show="ssoState.user" class="text-center">
    <v-menu v-model="menu" offset-y content-class="elevation-3">
      <template v-slot:activator="{ on }">
        <v-btn depressed icon v-on="on" class="body-2">
          <v-icon>mdi-apps</v-icon>
        </v-btn>
      </template>

      <v-card flat light min-width="320" max-width="320" width="320" class="px-4 py-2">
        <v-row>
          <v-col v-if="isNovoAdmin" cols="4">
            <v-btn text height="80" width="80" :href="admin">
              <div class="d-flex flex-column">
                <v-icon size="36" color="grey darken-4">mdi-shield-account</v-icon>
                <div class="text-center subtitle-2">Admin</div>
              </div>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn text height="80" width="80" :href="rpm">
              <div class="d-flex flex-column">
                <v-icon size="36" color="red accent-3">mdi-heart-pulse</v-icon>
                <div class="text-center subtitle-2">RPM</div>
              </div>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn text height="80" width="80" :href="orgs">
              <div class="d-flex flex-column">
                <v-icon size="36" color="deep-purple darken-3">mdi-account-group</v-icon>
                <div class="text-center subtitle-2">My Org</div>
              </div>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn text height="80" width="80" :href="enrollment">
              <div class="d-flex flex-column">
                <v-icon size="36" color="green darken-1">mdi-clipboard-account</v-icon>
                <div class="text-center subtitle-2">Enroll</div>
              </div>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn text height="80" width="80" :href="telehealth">
              <div class="d-flex flex-column">
                <v-icon size="36" color="grey darken-1">mdi-video-plus</v-icon>
                <div class="text-center subtitle-2">TeleHealth</div>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { state as ssoState, isNovoAdmin } from '../store/sso'

export default defineComponent({
  props: {
    color: {
      default: 'primary',
    },
    admin: {
      default: 'https://admin.novorpm.com',
    },
    rpm: {
      default: 'https://app.novorpm.com',
    },
    orgs: {
      default: 'https://orgs.novorpm.com',
    },
    enrollment: {
      default: 'https://enrollment.novorpm.com',
    },
    telehealth: {
      default: 'https://video.novo.health',
    },
  },
  setup() {
    const menu = ref(false)

    return {
      menu,
      isNovoAdmin,
      ssoState,
    }
  },
})
</script>

import { isStandalone } from '../store/url'

function hslToHex(h, s, l) {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

function xmur3(str) {
  for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
    (h = Math.imul(h ^ str.charCodeAt(i), 3432918353)), (h = (h << 13) | (h >>> 19))
  return function() {
    h = Math.imul(h ^ (h >>> 16), 2246822507)
    h = Math.imul(h ^ (h >>> 13), 3266489909)
    return (h ^= h >>> 16) >>> 0
  }
}

let _label = document.title
let _color

function updateColor() {
  const rand = Number('0.' + xmur3(_label)())
  const brightColor = { h: Math.floor(rand * 256), s: 100, l: 50 }
  _color = hslToHex(brightColor.h, brightColor.s, brightColor.l)
}

export const setConsoleLabel = label => {
  _label = label || _label
  updateColor()
}

const _console = window.console
updateColor()

if (!isStandalone) {
  window.console = {
    log: (...rest) => {
      _console.log(`%c[ ${_label} ]`, `color: ${_color}`, ...rest)
    },
    warn: (...rest) => {
      _console.warn(`%c[ ${_label} ]`, `color: ${_color}`, ...rest)
    },
    info: (...rest) => {
      _console.info(`%c[ ${_label} ]`, `color: ${_color}`, ...rest)
    },
    error: (...rest) => {
      _console.error(`%c[ ${_label} ]`, `color: ${_color}`, ...rest)
    },
  }
}

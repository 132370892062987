import Vue from 'vue'
import VueRouter from 'vue-router'
import { onAppReady } from '../shared-ui/hooks'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import PasswordReset from '../views/PasswordReset.vue'
import Profile from '../views/Profile.vue'
import { WebAuth } from 'auth0-js'
import { config, getAuth0IdToken, getRefreshTokenFromAuth0IdToken } from '../shared-ui/store/sso'
import { useLocalStorage } from '../shared-ui/helpers/use'
import { signOut } from '../shared-ui/store/googleapis'
import { setAuthStatus } from '@arrowhealth/bridge-sdk'

Vue.use(VueRouter)

const bridgeRedirectUrl = useLocalStorage('auth-bridge::redirect', { expiresInMinutes: 5 })
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      transition: 'slide-right',
    },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      transition: 'slide-left',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/auth/bridge',
    name: 'AuthBridge',
    component: { template: '<div>{{$router.query.err}}</div>' },
    beforeEnter: async to => {
      // if we are authenticating as another user,
      // be sure to sign out of existing user
      await signOut()

      bridgeRedirectUrl.value = to.query.redirect

      const webAuth = new WebAuth({
        domain: 'sso.bridge.arrowhealth.io',
        clientID: 'AfKmR9UHfKZRvSu8nB1gRVfPxNFnSuGf',
        redirectUri: location.origin + location.pathname + '/callback', // + '?debug=1',
        responseType: 'code',
        scope: 'openid email',
        __tryLocalStorageFirst: true,
      })
      webAuth.authorize()
      return false
    },
  },
  {
    path: '/auth/bridge/callback',
    name: 'AuthBridgeCallback',
    component: { template: '<div>{{$router.query.err}}</div>' },
    beforeEnter: async (to, from, next) => {
      let refreshToken
      // if err, user did not consent
      if (to.query.err) {
        // console.log('err:', to.query.err)
        return true
      }

      // if code, user did consent
      if (to.query.code) {
        // console.log('code:', to.query.code)
        await getAuth0IdToken(to.query.code, location.origin)
        try {
          await getRefreshTokenFromAuth0IdToken()
          setAuthStatus('pass')
          // await getAccessToken(refreshToken)
          // next({ name: 'Profile' })
          // return
        } catch (e) {
          next({ name: 'Login' })
          return
        }
      }

      const redirectUrl = bridgeRedirectUrl.value
      // if redirect in storage, redirect back to original app
      if (redirectUrl) {
        bridgeRedirectUrl.value = ''
        const url = new URL(decodeURIComponent(redirectUrl))
        url.searchParams.append(config.REFRESH_TOKEN_ID, refreshToken)
        location.href = url.toString()
        return false
      } else {
        next({ name: 'Profile' })
      }

      return true
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      transition: 'slide-left',
      logoSmallOnly: true,
    },
    beforeEnter(to, from, next) {
      onAppReady(
        authUser => {
          if (authUser) {
            next()
          } else {
            router.push({ name: 'Login', replace: true, query: router.currentRoute.query })
          }
        },
        { skipLastActivityCheck: true, skipNavToSSO: true }
      )
    },
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
